import React, { Component } from 'react';
import Card from '../../../containers/Tasks/Card';
import DefaultCard from '../../../containers/Tasks/DefaultCard';
import * as Actions from "../../actions/tasksActions"
import { connect } from "react-redux"
import AuthContext from "../../../services/Auth"
import Loader from "../../../containers/Loader/Loader"

let weekNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
class Tasks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentDate: "",
            loader: true,
            totalTime: 0,
            search: ""
        }
    }

    // componentDidMount() {
    //     var loggedInUser = AuthContext.getCachedUser();
    //     var dateNow = new Date(Date.now());
    //     this.props.getTasks(loggedInUser.profile.oid, dateNow.getFullYear() + "-" + (dateNow.getMonth() + 1) + "-" + dateNow.getDate());
    //     var dateArray = [];
    //     var changeDate = new Date(Date.now());
    //     changeDate.setDate(changeDate.getDate() - 14);
    //     dateArray.push(changeDate.getFullYear() + "-" + (changeDate.getMonth() + 1) + "-" + changeDate.getDate());
    //     for (var index = 0; index < 14; index++) {
    //         changeDate.setDate(changeDate.getDate() + 1);
    //         dateArray.push(changeDate.getFullYear() + "-" + (changeDate.getMonth() + 1) + "-" + changeDate.getDate());
    //     }
    //     this.setState({ user: loggedInUser, dateArray: dateArray, currentDate: dateNow.getFullYear() + "-" + (dateNow.getMonth() + 1) + "-" + dateNow.getDate() })
    //     this.props.getDefaultEventsList(loggedInUser.profile.oid, dateNow.getFullYear() + "-" + (dateNow.getMonth() + 1) + "-" + dateNow.getDate());

    // }  //Two weeks Implementation

    componentDidMount() {
    var loggedInUser = AuthContext.getCachedUser();
    var dateNow = new Date(Date.now());
    var currentDateString = `${dateNow.getFullYear()}-${dateNow.getMonth() + 1}-${dateNow.getDate()}`;
    
    // Call API with the current date
    this.props.getTasks(loggedInUser.profile.oid, currentDateString);
    this.props.getDefaultEventsList(loggedInUser.profile.oid, currentDateString);
    
    // Generate dateArray from October 1st to today
    var dateArray = [];
    var startYear = dateNow.getMonth() < 9 ? dateNow.getFullYear() - 1 : dateNow.getFullYear(); // If before October, use the previous year
    var changeDate = new Date(`${startYear}-10-01`); // Start from October 1st of the determined year
    
    while (changeDate <= dateNow) {
        dateArray.push(`${changeDate.getFullYear()}-${changeDate.getMonth() + 1}-${changeDate.getDate()}`);
        changeDate.setDate(changeDate.getDate() + 1); // Increment the day
    }
    this.setState({ 
        user: loggedInUser, 
        dateArray: dateArray, 
        currentDate: currentDateString 
    });
}

    componentDidUpdate(prevProps, prevState) {
        if (prevState.currentDate !== this.state.currentDate) {
            const selectedDateElement = document.querySelector(".date-picker-active");
            const dateListElement = document.getElementById("dateList");
    
            if (selectedDateElement && dateListElement) {
                const dateListRect = dateListElement.getBoundingClientRect();
                const selectedDateRect = selectedDateElement.getBoundingClientRect();
    
                // Check if the selected date is out of view on the left or right and adjust scroll
                if (selectedDateRect.left < dateListRect.left) {
                    dateListElement.scrollLeft -= dateListRect.left - selectedDateRect.left;
                } else if (selectedDateRect.right > dateListRect.right) {
                    dateListElement.scrollLeft += selectedDateRect.right - dateListRect.right;
                }
            }
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.task.projects && props.task.events) {
            var totalTime = 0;
            for (var projectIndex in props.task.projects) {
                totalTime = totalTime + props.task.projects[projectIndex].time;
            }
            for (var index in props.task.events) {
                totalTime = totalTime + props.task.events[index].time;
            }
            return {
                totalTime: totalTime,
                loader: false
            }
        }
    }

    render() {
        const { search, dateArray, currentDate, loader } = this.state;
    
       // Filtered projects based on the search input
        const filteredProjects = (this.props.task.projects || []).filter(project => 
            project.name && project.name.toLowerCase().includes((search || "").toLowerCase())
        );
    
        // Calculate total time from filtered projects and events
        const totalTime = filteredProjects.reduce((acc, project) => acc + project.time, 0) +
                          (this.props.task.events || []).reduce((acc, event) => acc + event.time, 0);

        return (
            <div className="contain-fluid">
            <div className="row table-container" style={{ height: window.innerHeight - 50, overflow: "hidden", width: window.innerWidth - 250 }}>
                {
                    loader &&
                    <Loader />
                }
                <div className="col-md-8 row"  >
                    <div id="dateList" className="col-md-12 date-picker-list scroll" >
                        {dateArray &&
                          dateArray.map((date, index) => {
                                var dateValue = new Date(date);
                                var day = dateValue.getDay();
                                return (<div
                                        key={index}
                                    onClick={() => {
                                        this.props.getDefaultEventsList(this.state.user.profile.oid, date);
                                        this.props.getTasks(this.state.user.profile.oid, date);
                                        this.setState({ currentDate: date })
                                    }}
                                    className={(date === currentDate ? "date-picker date-picker-active" : "date-picker")}>
                                    <h6 style={{ textAlign: "center", paddingBottom: 0, fontSize: 13 }}>{date}</h6>
                                    <h6 style={{ textAlign: "center", paddingBottom: 0, fontSize: 13 }}>{weekNames[day]}</h6>
                                </div>
                                );
                            })}
                        </div>
                        <div className="col-md-12">
                            <div className="task-container scroll" style={{ padding: 5, height: window.innerHeight - 125, overflowY: "scroll" }}>
                                <div className="col-md-12 row">
                                    <input 
                                        className="search_input" 
                                        onChange={(e) => 
                                           this.setState({search: e.target.value})
                                        }
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                this.setState({search: e.target.value});
                                            }
                                        }}
                                    />
                                    <i className="fa fa-search" style={{ marginLeft: 10 }} />
                                </div>
                                <div className="col-md-12 row" style={{ marginBottom: '20%', marginTop: '1%' }}>
                                    {filteredProjects.length > 0 ? (
                                        filteredProjects.map((project, index) => (
                                             <Card
                                                key={index}
                                                index={index}
                                                dateString={currentDate}
                                                project={project}
                                                reduceTaskTime={(index, projectAssignId, dateString, id, projectId) => this.props.reduceTaskTime(index, projectAssignId, dateString, id, projectId)}
                                                addTaskTime={(index, projectAssignId, dateString, id, projectId) => this.props.addTaskTime(index, projectAssignId, dateString, id, projectId)}
                                                projectName={project.name}
                                                image={project.image}
                                                description={project.message}
                                                currentHours={project.currentHours}
                                                expectedHours={project.expectedHours}
                                                userCurrentTime={project.userCurrentTime}
                                                userTime={project.userTime}
                                                time={project.time}
                                                totalTime={totalTime} />                
                                        ))
                                    ) : (
                                    <div style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>

                                        <span style={{ color: "gray", fontSize: 12, textAlign: "center" }}>Tasks are not available</span>
                                    </div>
                                    )}
                                </div>
                            
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="task_event_card scroll" style={{ height: window.innerHeight - 65 }}>
                        {this.props.task && this.props.task.events && this.props.task.events.length != 0 && this.props.task.events.map((event, index) => {
                            return <DefaultCard
                                date={this.state.currentDate}
                                addTime={(id, defaultProjectId, value) => { this.props.addEventsTime(this.state.user.userName, this.state.user.profile.oid, this.state.user.profile.name, id, defaultProjectId, this.state.currentDate, value, index) }}
                                reduceTime={(id, value) => { this.props.reduceEventTime(id, value, index) }}
                                event={event}
                                key={index}
                                totalTime={this.state.totalTime}
                            />
                        })}

                    </div>
                </div>
            </div>
        </div >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        task: state.taskReducers
    }
}

export default connect(mapStateToProps, Actions)(Tasks);